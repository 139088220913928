import React from 'react';
import Flare from 'web/components/Flare';
import LinkifyStyled from 'web/components/LinkifyStyled';
import RichTextContent from 'web/components/portable-text/ArticleContent';
import { isStringifiedPortableText, parsePortableText } from 'web/components/portable-text/utils';
import sc from 'web/components/styled';
import useErrorReporter from 'web/hooks/useErrorReporter';
import { formatCurrencyAmount, minChargeAmount } from 'web/utils/currency';
import { formatDurationMins } from 'web/utils/dateFormat';
import { cover, coverImg, descriptionText, layout, main, photo, photoImg, sidebarContainer, title } from './common.css';

const Title = sc.h1(title);
const Cover = sc.div(cover);
const Photo = sc.div(photo);
const SidebarContainer = sc.div(sidebarContainer);
const Main = sc.div(main);
const Layout = sc.div(layout);
const CoverImg = sc.img(coverImg);
const PhotoImg = sc.img(photoImg);

export type BookingDetails = {
  firstName: string;
  lastName: string;
  email: string;
  message: string;
  phoneNumber?: string;
};

export type SlotTimeRange = {
  start: Date;
  end: Date;
};

export type SlotTimeZoneTimeRange = SlotTimeRange & {
  timezone: string;
};

export type PersonalSessionSlot = SlotTimeZoneTimeRange & {
  isGroup?: false;
  title: string;
  serviceId: string;
  price: number;
  currency: introwise.Currency;
};

export type GroupSessionSlot = SlotTimeZoneTimeRange & {
  isGroup: true;
  sessionId: string;
  title: string;
  price: number;
  currency: introwise.Currency;
};

export type Slot = PersonalSessionSlot | GroupSessionSlot;

const formatPriceReadable = (price: number, currency: introwise.Currency) =>
  price ? formatCurrencyAmount(price, currency) : 'Free';

export type ServiceProduct = {
  type: 'service';
  serviceId: string;
  start: Date;
  service: introwise.Service;
};

export type SessionProduct = {
  type: 'session';
  sessionId: string;
  session: introwise.GroupSession;
};

export type SessionChangeProduct = {
  type: 'session-change';
  sessionId: string;
  session: introwise.PersonalSession;
  newStart?: Date;
};

export type PackageProduct = {
  type: 'package';
  packageId: string;
  package: introwise.Package;
};

export type Product = ServiceProduct | SessionProduct | PackageProduct | SessionChangeProduct;

export type Discount = { code: string } & (
  | {
      type: 'partner';
      partnerId: string;
      partnerCodeId: string;
    }
  | {
      type: 'discount';
      discountCodeId: string;
    }
) & {
    discount:
      | {
          valueType: 'percentage';
          value: {
            percentOff: number;
          };
        }
      | {
          valueType: 'fixed';
          value: {
            amountOff: number;
            currency: introwise.Currency;
          };
        };
  };

const calculateAmountWithDiscount = (
  amount: number,
  currency: introwise.Currency,
  discount: Discount | null,
  paymentsCount = 1,
) => {
  if (!discount) {
    return amount;
  }
  if (
    discount.type === 'discount' &&
    discount.discount.valueType === 'fixed' &&
    discount.discount.value.currency !== currency
  ) {
    // Cannot apply discount on different currencies
    return amount;
  }

  if (discount.type === 'partner') {
    return 0;
  }

  let discountedAmount;
  if (discount.discount.valueType === 'percentage') {
    discountedAmount = amount - (amount * discount.discount.value.percentOff) / 100;
  } else {
    discountedAmount = amount - discount.discount.value.amountOff / paymentsCount;
  }
  discountedAmount = Math.round(discountedAmount);
  discountedAmount = Math.max(discountedAmount, 0);
  discountedAmount = Math.min(discountedAmount, amount);
  discountedAmount = discountedAmount > 0 ? Math.max(discountedAmount, minChargeAmount[currency]) : discountedAmount;
  return discountedAmount;
};

const formatDiscountReadable = (discount: Discount) =>
  discount.discount.valueType === 'percentage'
    ? `${discount.discount.value.percentOff}% off`
    : `${formatCurrencyAmount(discount.discount.value.amountOff, discount.discount.value.currency)} off`;

type ContentErrorBoundaryWithReporterProps = {
  children: React.ReactNode;
  errorReporter: typeof import('web/utils/error-reporter').default;
};

class ContentErrorBoundaryWithReporter extends React.Component<
  ContentErrorBoundaryWithReporterProps,
  { hasError?: boolean }
> {
  constructor(props: ContentErrorBoundaryWithReporterProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    try {
      this.props.errorReporter.report(error);
    } catch (e) {
      console.error('Failed to log the error in ErrorBoundary: ', e);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <p>
          <Flare variant="error">Something went wrong. Cannot display this content at the moment.</Flare>
        </p>
      );
    }

    return this.props.children;
  }
}

const ContentErrorBoundary = ({ children }: { children: React.ReactNode }) => {
  const errorReporter = useErrorReporter();
  return <ContentErrorBoundaryWithReporter errorReporter={errorReporter}>{children}</ContentErrorBoundaryWithReporter>;
};

const DescriptionTextPlain = ({ text }: { text: string }) => (
  <p className={descriptionText}>
    <LinkifyStyled>{text}</LinkifyStyled>
  </p>
);

const DescriptionTextBlockContent = ({ portableTextString }: { portableTextString: string }) => {
  const blocks = parsePortableText(portableTextString);
  return <RichTextContent blocks={blocks} userGenerated />;
};

const DescriptionTextImpl = ({ text }: { text: string }) => {
  return isStringifiedPortableText(text) ? (
    <ContentErrorBoundary>
      <DescriptionTextBlockContent portableTextString={text} />
    </ContentErrorBoundary>
  ) : (
    <DescriptionTextPlain text={text} />
  );
};

const DescriptionText = React.memo(DescriptionTextImpl);

export {
  Layout,
  Main,
  Photo,
  Cover,
  PhotoImg,
  CoverImg,
  SidebarContainer,
  Title,
  formatPriceReadable,
  formatDurationMins,
  calculateAmountWithDiscount,
  formatDiscountReadable,
  DescriptionText,
};
