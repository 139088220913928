import type { EventApi, EventContentArg } from '@fullcalendar/react';
import Tooltip from 'web/components/Tooltip';
import React from 'react';
import styled from 'styled-components';
import themeConstants from 'web/styles/themeConstants';
import themeVars from 'web/styles/themeVars.css';
import { formatSessionDateTimeString } from 'web/utils/dateFormat';

const Container = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${themeVars.button.background.primary};
  &:active,
  &:active:hover {
    background: ${themeVars.button.background.primaryActive};
  }
  &:hover {
    background: ${themeVars.button.background.primaryHover};
  }

  border: none;
  border-radius: 0;
`;

const StyledSpan = styled.span<{ isMobile?: boolean; isListView?: boolean; tiny?: boolean }>`
  color: ${themeVars.button.color.primary};
  display: ${({ isMobile }) => (isMobile ? `block` : `none`)};
  text-align: center;
  font-size: ${({ tiny }) => (tiny ? '10px' : '12px')};
  line-height: 1.2;
  font-weight: ${({ tiny }) => (tiny ? 'bold' : 'normal')};

  ${themeConstants.media.sm} {
    display: ${({ isMobile }) => (!isMobile ? `block` : `none`)};
  }
`;

const generateTitle = (event: EventApi) => {
  return formatSessionDateTimeString(event.start, event.end);
};

const eventContent = (eventInfo: EventContentArg) => {
  const isListView = eventInfo.view.type === 'listWeek';
  const small = eventInfo.event.end.getTime() - eventInfo.event.start.getTime() < 1800000;
  const tiny = eventInfo.event.end.getTime() - eventInfo.event.start.getTime() < 900000;

  return (
    <Tooltip label={generateTitle(eventInfo.event)}>
      <Container>
        <StyledSpan isListView={isListView} tiny={tiny}>
          {small ? eventInfo.event.extendedProps.mobileTitle : eventInfo.event.title}
        </StyledSpan>
        <StyledSpan isMobile isListView={isListView} tiny={tiny}>
          {eventInfo.event.extendedProps.mobileTitle}
        </StyledSpan>
      </Container>
    </Tooltip>
  );
};

export default eventContent;
