import { useMachine } from '@xstate/react';
import { FirebaseError } from 'firebase/app';
import { httpsCallable } from 'firebase/functions';
import { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import useFunctions from 'web/components/FirebaseContext/useFunctions';
import useErrorReporter from 'web/hooks/useErrorReporter';
import CheckoutContext from '../CheckoutContext';
import { calculateAmountWithDiscount } from '../common';
import BookedPackageContext from '../packages/BookedPackageContext';
import useDiscountCode from '../useDiscountCode';
import usePartnerCode from '../usePartnerCode';
import orderStateMachine from './orderStateMachine';
import PayActionClientError from './PayActionClientError';

const convertOrderDates = (
  order: introwise.FunctionsSerializedData<introwise.Order>,
  convert: (date: number) => Date,
): introwise.Order => ({
  ...order,
  product:
    order.product.type === 'service'
      ? {
          ...order.product,
          start: convert(order.product.start),
        }
      : order.product,
  createdAt: convert(order.createdAt),
  updatedAt: convert(order.updatedAt),
});

const defaultErrorMessage = 'Something went wrong. Please try again later.';

const useOrderStateMachine = ({ page, initialOrderId }: { page: introwise.Page; initialOrderId: string }) => {
  const [product, , , setDiscount] = useContext(CheckoutContext);
  const verifyPartnerCode = usePartnerCode();
  const verifyDiscountCode = useDiscountCode();
  const { isServiceAvailable, isSeriesAvailable, bookedPackage } = useContext(BookedPackageContext);
  const functions = useFunctions();
  const errorReporter = useErrorReporter();
  // TODO: abstract this out
  const [searchParams, setSearchParams] = useSearchParams();

  const applyDiscount = async (orderUpdated: introwise.Order): Promise<introwise.Order> => {
    const res = await httpsCallable<unknown, introwise.FunctionsSerializedData<introwise.Order>>(
      functions,
      'pagesOrdersMutate',
    )({
      action: 'applyDiscount',
      pageId: page.id,
      order: orderUpdated,
    });
    const orderSerialized = res.data;
    const order = convertOrderDates(orderSerialized, (num) => new Date(num));
    return order;
  };

  const machine = useMachine(orderStateMachine, {
    context: {
      ...(initialOrderId && { initialOrderId }),
    },
    actions: {
      prepare: async () => {
        try {
          if (product.type === 'session-change') {
            throw new Error('Session change is not supported');
          }
          const orderProduct: introwise.OrderProduct =
            product.type === 'session'
              ? {
                  type: 'session',
                  sessionId: product.sessionId,
                }
              : product.type === 'service'
              ? { type: 'service', serviceId: product.serviceId, start: product.start }
              : { type: 'package', packageId: product.packageId };
          const isSessionFromPackage =
            (product.type === 'service' && isServiceAvailable(product.serviceId, product.start)) ||
            (product.type === 'session' && isSeriesAvailable(product.session.seriesId, product.session.start));

          const currency =
            product.type === 'session'
              ? product.session.currency
              : product.type === 'service'
              ? page.currency
              : product.package.currency;
          const amountOriginal =
            product.type === 'session'
              ? product.session.price
              : product.type === 'service'
              ? product.service.price
              : product.package.price;
          const isFreeProduct = amountOriginal === 0;

          let order: introwise.Order;
          if (isSessionFromPackage) {
            // Shortcut for session from package
            order = {
              id: null,
              status: 'draft',
              createdAt: new Date(),
              updatedAt: new Date(),
              product: orderProduct,
              amountSubtotal: amountOriginal,
              amountTotal: 0,
              amountDue: 0,
              currency,
              package: {
                packageId: bookedPackage.package.id,
                bookedPackageId: bookedPackage.id,
              },
            };
          } else if (isFreeProduct) {
            // Shortcut for free products
            order = {
              id: null,
              status: 'draft',
              createdAt: new Date(),
              updatedAt: new Date(),
              product: orderProduct,
              amountSubtotal: 0,
              amountTotal: 0,
              amountDue: 0,
              currency,
            };
          } else {
            const res = await httpsCallable<unknown, introwise.FunctionsSerializedData<introwise.Order>>(
              functions,
              'pagesOrdersMutate',
            )({
              action: 'prepare',
              pageId: page.id,
              order: {
                product: orderProduct,
              },
            });
            const orderSerialized = res.data;
            order = convertOrderDates(orderSerialized, (num) => new Date(num));
          }

          // TODO: abstract this out
          if (order.id) {
            searchParams.set('orderId', order.id);
            setSearchParams(searchParams, { replace: true });
          }

          send('PREPARE_SUCCESS', { order });
        } catch (err) {
          errorReporter.report(err);
          const error = defaultErrorMessage;
          send('PREPARE_FAILED', { error });
        }
      },
      load: async (context) => {
        if (!context.initialOrderId) {
          send('ERROR');
        }
        try {
          const res = await httpsCallable<unknown, introwise.FunctionsSerializedData<introwise.Order>>(
            functions,
            'pagesOrdersMutate',
          )({
            action: 'confirm',
            pageId: page.id,
            order: {
              id: context.initialOrderId,
            },
          });
          const orderSerialized = res.data;
          const order = convertOrderDates(orderSerialized, (num) => new Date(num));

          if (order.partner) {
            setDiscount({
              type: 'partner',
              code: order.partner.code,
              partnerId: order.partner.partnerId,
              partnerCodeId: order.partner.codeId,
              discount: {
                valueType: 'percentage',
                value: {
                  percentOff: 100,
                },
              },
            });
          }
          if (order.discount) {
            setDiscount({
              type: 'discount',
              code: order.discount.code,
              discountCodeId: order.discount.codeId,
              discount:
                order.discount.valueType === 'percentage'
                  ? {
                      valueType: 'percentage',
                      value: order.discount.value,
                    }
                  : {
                      valueType: 'fixed',
                      value: order.discount.value,
                    },
            });
          }

          send('LOAD_SUCCESS', { order });
        } catch (err) {
          errorReporter.report(err);
          let error = defaultErrorMessage;
          if (err instanceof FirebaseError) {
            if (err.code === 'functions/not-found') {
              error = `This order is no longer available`;
            }
          }
          send('LOAD_FAILED', { error });
        }
      },
      submit: async (context, event) => {
        try {
          const orderSubmit = {
            ...context.order,
            ...event.order,
          };

          const res = await httpsCallable<unknown, introwise.FunctionsSerializedData<introwise.Order>>(
            functions,
            'pagesOrdersMutate',
          )({
            action: 'submit',
            pageId: page.id,
            order: orderSubmit,
          });
          const orderSerialized = res.data;
          const order = convertOrderDates(orderSerialized, (num) => new Date(num));
          send('SUBMIT_SUCCESS', { order });
        } catch (err) {
          errorReporter.report(err);
          let error = defaultErrorMessage;
          if (err instanceof FirebaseError) {
            if (err.code === 'functions/failed-precondition') {
              error = `This ${context.order.product.type} is no longer available`;
            }
          }
          send('SUBMIT_FAILED', { error });
        }
      },
      pay: async (context) => {
        if (!context.payAction) {
          send('ERROR');
        } else {
          try {
            await context.payAction(context.order);
            send('PAYMENT_SUCCESS');
          } catch (err) {
            errorReporter.report(err);
            const error = err instanceof PayActionClientError ? err.message : defaultErrorMessage;
            send('PAYMENT_FAILED', { error });
          }
        }
      },
      confirm: async (context) => {
        try {
          const res = await httpsCallable<unknown, introwise.FunctionsSerializedData<introwise.Order>>(
            functions,
            'pagesOrdersMutate',
          )({
            action: 'confirm',
            pageId: page.id,
            order: {
              id: context.order.id,
            },
          });
          const orderSerialized = res.data;
          const order = convertOrderDates(orderSerialized, (num) => new Date(num));
          send('CONFIRM_SUCCESS', { order });
        } catch (err) {
          errorReporter.report(err);
          let error = defaultErrorMessage;
          if (err instanceof FirebaseError) {
            if (err.code === 'functions/not-found') {
              error = `This order is no longer available`;
            }
          }
          send('CONFIRM_FAILED', { error });
        }
      },
      reset: async (context) => {
        try {
          const res = await httpsCallable<unknown, introwise.FunctionsSerializedData<introwise.Order>>(
            functions,
            'pagesOrdersMutate',
          )({
            action: 'reset',
            pageId: page.id,
            order: {
              id: context.order.id,
            },
          });
          const orderSerialized = res.data;
          const order = convertOrderDates(orderSerialized, (num) => new Date(num));
          send('RESET_SUCCESS', { order });
        } catch (err) {
          errorReporter.report(err);
          const error = defaultErrorMessage;
          send('RESET_FAILED', { error });
        }
      },
      applyDiscount: async (context, event) => {
        try {
          const { code } = event as unknown as { code: string | null };
          if (!code) {
            const { discount, partner, amountSubtotal, amountDue, amountTotal, ...orderPartial } = context.order;
            const orderUpdated: introwise.Order = {
              ...orderPartial,
              amountSubtotal,
              amountTotal: amountSubtotal,
              amountDue: amountSubtotal,
            };
            const order = await applyDiscount(orderUpdated);
            setDiscount(null);
            send('APPLY_DISCOUNT_SUCCESS', { order });
          } else {
            const isPartner = code.includes('_');
            if (isPartner) {
              const result = await verifyPartnerCode(code, page.id);
              if (!result.error) {
                const { partnerId, partnerCodeId, discount } = result;
                const amountTotal = discount
                  ? calculateAmountWithDiscount(
                      context.order.amountSubtotal,
                      context.order.currency as introwise.Currency,
                      {
                        type: 'discount',
                        code,
                        discountCodeId: null,
                        discount,
                      },
                    )
                  : context.order.amountSubtotal;

                const amountDue = calculateAmountWithDiscount(
                  amountTotal,
                  context.order.currency as introwise.Currency,
                  {
                    type: 'partner',
                    code,
                    partnerId,
                    partnerCodeId,
                    discount: {
                      valueType: 'percentage',
                      value: {
                        percentOff: 100,
                      },
                    },
                  },
                );

                const orderUpdated: introwise.Order = {
                  ...context.order,
                  amountTotal,
                  amountDue,
                  partner: {
                    code,
                    codeId: partnerCodeId,
                    partnerId,
                    ...(discount && {
                      discount: {
                        valueType: discount.valueType,
                        value: discount.value,
                        amount: context.order.amountSubtotal - amountTotal,
                      },
                    }),
                  },
                };
                const order = await applyDiscount(orderUpdated);
                setDiscount({
                  type: 'partner',
                  code,
                  partnerId,
                  partnerCodeId,
                  discount: {
                    valueType: 'percentage',
                    value: {
                      percentOff: 100,
                    },
                  },
                });
                send('APPLY_DISCOUNT_SUCCESS', { order });
              } else {
                send('APPLY_DISCOUNT_FAILED', { error: result.error });
              }
            } else {
              const result = await verifyDiscountCode(code, page.id, context.order.currency as introwise.Currency);
              if (!result.error) {
                const { discountCodeId, discount } = result;

                let amountTotal;
                let amountDue;

                if (!context.order.paymentPlan) {
                  amountTotal = calculateAmountWithDiscount(
                    context.order.amountSubtotal,
                    context.order.currency as introwise.Currency,
                    {
                      type: 'discount',
                      code,
                      discountCodeId,
                      discount,
                    },
                  );
                  amountDue = context.order.payment?.gateway === 'none' ? 0 : amountTotal;
                } else {
                  // When payment plan is applied, discount is calculated on the amount due and the total amount separately
                  // and then last payment plan payment is adjusted to match the total amount
                  amountDue = calculateAmountWithDiscount(
                    context.order.amountDue,
                    context.order.currency as introwise.Currency,
                    {
                      type: 'discount',
                      code,
                      discountCodeId,
                      discount,
                    },
                    context.order.paymentPlan.count,
                  );

                  amountTotal = calculateAmountWithDiscount(
                    context.order.amountSubtotal,
                    context.order.currency as introwise.Currency,
                    {
                      type: 'discount',
                      code,
                      discountCodeId,
                      discount,
                    },
                  );
                }

                const orderUpdated: introwise.Order = {
                  ...context.order,
                  amountTotal,
                  amountDue,
                  discount: {
                    code,
                    codeId: discountCodeId,
                    amount: context.order.amountSubtotal - amountTotal,
                    ...discount,
                  },
                };
                const order = await applyDiscount(orderUpdated);
                setDiscount({
                  type: 'discount',
                  code,
                  discountCodeId,
                  discount,
                });
                send('APPLY_DISCOUNT_SUCCESS', { order });
              } else {
                send('APPLY_DISCOUNT_FAILED', { error: result.error });
              }
            }
          }
        } catch (err) {
          errorReporter.report(err);
          send('APPLY_DISCOUNT_FAILED', { error: 'Failed to apply discount code. Please try again.' });
        }
      },
    },
  });
  const [, send] = machine;

  return machine;
};

export default useOrderStateMachine;
