import React, { ReactNode } from 'react';
import { Tooltip as BaseTooltip } from '@base-ui-components/react/tooltip';
import { tooltipPopup } from './tooltip.css';

interface TooltipProps {
  label: ReactNode;
  children: ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ label, children }) => {
  return (
    <BaseTooltip.Provider>
      <BaseTooltip.Root>
        <BaseTooltip.Trigger render={children} />
        <BaseTooltip.Portal>
          <BaseTooltip.Positioner align="center">
            <BaseTooltip.Popup className={tooltipPopup}>{label}</BaseTooltip.Popup>
          </BaseTooltip.Positioner>
        </BaseTooltip.Portal>
      </BaseTooltip.Root>
    </BaseTooltip.Provider>
  );
};

export default Tooltip;
